import { useMemo, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form"
import { Button } from '@nike/eds'
import { DatePicker, InputGroup, TextArea } from "../../shared/react-form"
import { SidePanel, Text } from "@nike/eds"
import { Dropdown } from "../../shared/react-form/Dropdown"
import { FinanceOverviewFilterFields } from '../../model/FinanceOverviewModels'
import { MultiselectDropdown } from '../../shared/react-form/MultiselectDropdown'
import { PlantCodeOptions } from '../filter/PlantCodeOptions'
import {returnsOverviewFilterStore} from './ReturnsOverviewFilterStore'

const formId = "FinanceOverviewForm"

const customerTypeOptions = [
    {
        value: true,
        label: "Full Tolerance"
    },
    {
        value: false,
        label: "No Tolerance"
    }
]

const statusOptions = [
    {
        value: 'APPROVED',
        label: 'Approved'
    },
    {
        value: 'RECEIVED',
        label: 'Received'
    },
    {
        value: 'COMPLETED',
        label: 'Completed'
    },
    {
        value: 'COMPLETED, NOT CLOSED / NOT CREDITED',
        label: 'Completed, Not Closed / Not Credited'
    },
    {
        value: 'CANCELED',
        label: 'Canceled'
    },
]

const emptyFormData = {
    returnSalesOrderNumbers: '',
    soldToCustomers: '',
    shipToCustomers: '',
    salesOrganisationCodes: '',
    approvalDateFrom: '',
    approvalDateTo: '',
    receivedDateTimeFrom: '',
    receivedDateTimeTo: '',
    plantCodes: '',
    customerType: '',
    status: '',
}

const mapStringToArrayOrUndefined = (value) =>
    value ? value.trim().split(/[\s:,;\n]+/).map(s => s.trim()) : undefined

const useFilterForm = (isOpen, initData: FinanceOverviewFilterFields) => {
    const _formData = useMemo(() => {
        const storedData = returnsOverviewFilterStore.getFinanceFilterStoredDataOrEmptyData()

        const {
            returnSalesOrderNumbers = returnsOverviewFilterStore.getFieldValue(storedData,'returnSalesOrderNumbers'),
            soldToCustomers = returnsOverviewFilterStore.getFieldValue(storedData,'soldToCustomers'),
            shipToCustomers = returnsOverviewFilterStore.getFieldValue(storedData,'shipToCustomers'),
            salesOrganisationCodes = returnsOverviewFilterStore.getFieldValue(storedData,'salesOrganisationCodes'),
            approvalDateFrom = returnsOverviewFilterStore.getFieldValue(storedData,'approvalDateFrom'),
            approvalDateTo = returnsOverviewFilterStore.getFieldValue(storedData,'approvalDateTo'),
            receivedDateTimeFrom = returnsOverviewFilterStore.getFieldValue(storedData,'receivedDateTimeFrom'),
            receivedDateTimeTo = returnsOverviewFilterStore.getFieldValue(storedData,'receivedDateTimeTo'),
            status = returnsOverviewFilterStore.getFieldValue(storedData, 'status'),
            plantCodes = returnsOverviewFilterStore.getFieldValue(storedData,'plantCodes'),
            customerType = returnsOverviewFilterStore.getFieldValue(storedData,'customerType'),
        } = initData ?? {}

        return isOpen ? {
            returnSalesOrderNumbers: returnSalesOrderNumbers.join('\n') ?? returnsOverviewFilterStore.getFieldValue(storedData,'returnSalesOrderNumbers'),
            soldToCustomers: soldToCustomers.join('\n') ?? returnsOverviewFilterStore.getFieldValue(storedData,'soldToCustomers'),
            shipToCustomers: shipToCustomers.join('\n') ?? returnsOverviewFilterStore.getFieldValue(storedData,'shipToCustomers'),
            salesOrganisationCodes: salesOrganisationCodes.join('\n') ?? returnsOverviewFilterStore.getFieldValue(storedData,'salesOrganisationCodes'),
            approvalDateFrom,
            approvalDateTo,
            receivedDateTimeFrom,
            receivedDateTimeTo,
            status: statusOptions.filter(o => status.includes(o.value)).map(o => ({ 'value': o.value, 'label': o.label })),
            plantCodes: plantCodes.map(o => ({ 'value': o, 'label': o })),
            customerType: customerTypeOptions.find(o => o.value === customerType),
        } : emptyFormData
    }, [isOpen, initData])

    const {reset, resetField, ...restForm} = useForm({
        defaultValues: _formData
    })

    useEffect(() => {
        // refresh the form state whenever we open it
        if (isOpen) {
            reset(_formData)
        }
    }, [isOpen, reset, _formData])

    // extra utility functions...
    const clear = useCallback(() => reset(emptyFormData), [reset])
    const clearField = useCallback((name, options = {}) =>
            resetField(name, { defaultValue: emptyFormData[name] ?? null, ...options }),
        [resetField]
    )

    return {
        ...restForm,
        reset,
        clear,
        resetField,
        clearField
    }
}

export function ReturnsOverviewFilter({  currentFilter: initData,
                                      onClickFilter,
                                      expand = false,
                                      onCloseSidePanel}:FinanceOverviewFilterProps) {

    const plantCodeOptions = PlantCodeOptions()
    const { control, handleSubmit, clear, clearField } = useFilterForm(expand, initData)
    const applyFilters = (data) => {
        // do some data sanitization before updating the higher state
        onClickFilter({
            returnSalesOrderNumbers: mapStringToArrayOrUndefined(data.returnSalesOrderNumbers),
            soldToCustomers: mapStringToArrayOrUndefined(data.soldToCustomers),
            shipToCustomers: mapStringToArrayOrUndefined(data.shipToCustomers),
            salesOrganisationCodes: mapStringToArrayOrUndefined(data.salesOrganisationCodes),
            approvalDateFrom: data.approvalDateFrom ? data.approvalDateFrom : undefined,
            approvalDateTo: data.approvalDateTo ? data.approvalDateTo : undefined,
            receivedDateTimeFrom: data.receivedDateTimeFrom ? data.receivedDateTimeFrom : undefined,
            receivedDateTimeTo: data.receivedDateTimeTo ? data.receivedDateTimeTo : undefined,
            status: data.status.length ? data.status.map(it => it.value) : undefined,
            plantCodes: data.plantCodes.length ? data.plantCodes.map(it => it.value) : undefined,
            customerType: data.customerType ? data.customerType.value : undefined,
        })
        onCloseSidePanel()
    }

    const header = <Text as={"h2"}>Filter</Text>

    const footer = <div className="flex-row content-space-between w-100">
        <div>
            <Button variant="primary" type="submit" form={formId} size="small">
                Apply
            </Button>
        </div>
        <div>
            <Button variant="secondary" size="small" onClick={clear}>
                Clear
            </Button>
        </div>
    </div>

    const formBody = <form id={formId} className="flex-column content-start h-100" onSubmit={handleSubmit(applyFilters)}>
        <InputGroup label="RSO Number" clearable={true} onClickClear={() => clearField('returnSalesOrderNumbers')}>
            <TextArea name="returnSalesOrderNumbers" control={control} label="RSO number"/>
        </InputGroup>
        <InputGroup label="Sold-To Customer" clearable={true} onClickClear={() => clearField('soldToCustomers')}>
            <TextArea name="soldToCustomers" control={control} label="Sold To Customer"/>
        </InputGroup>
        <InputGroup label="Ship-To Customer" clearable={true} onClickClear={() => clearField('shipToCustomers')}>
            <TextArea name="shipToCustomers" control={control} label="Ship To Customer"/>
        </InputGroup>
        <InputGroup label="Sales Organisation Code" clearable={true} onClickClear={() => clearField('salesOrganisationCodes')}>
            <TextArea name="salesOrganisationCodes" control={control} label="Sales Organisation Code"/>
        </InputGroup>
        <div className="flex-column content-space-between">
                <DatePicker
                    from={{
                        name: "approvalDateFrom"
                    }}
                    to={{
                        name: "approvalDateTo"
                    }}
                    title="Approval date"
                    control={control}
                />
        </div>
        <div className="flex-column content-space-between">
            <DatePicker
                from={{
                    name: "receivedDateTimeFrom"
                }}
                to={{
                    name: "receivedDateTimeTo"
                }}
                title="Received datetime"
                control={control}
            />
        </div>
        <InputGroup label="RSO Status" clearable={true} onClickClear={() => clearField('status')}>
            <MultiselectDropdown name="status" control={control} label="RSO Status" options={statusOptions}/>
        </InputGroup>
        <InputGroup label="Plant Code" clearable={true} onClickClear={() => clearField('plantCodes')}>
            <MultiselectDropdown name="plantCodes" control={control} label="Plant Code" options={plantCodeOptions}/>
        </InputGroup>
        <InputGroup label="Customer Type" clearable={true} onClickClear={() => clearField('customerType')}>
            <Dropdown name="customerType" control={control} label="Customer Type" options={customerTypeOptions}/>
        </InputGroup>
    </form>

    return (
        <SidePanel
            headerSlot={header}
            title="Filter"
            onDismiss={onCloseSidePanel}
            isOpen={expand}
            footerSlot={footer}
        >
            {formBody}
        </SidePanel>
    )
}



export interface FinanceOverviewFilterProps {
    currentFilter: FinanceOverviewFilterFields,
    onClickFilter: (formData: FinanceOverviewFilterFields) => void,
    expand: boolean,
    onCloseSidePanel: () => void
}
